@font-face {
font-family: '__objectivityMedium_f00cf9';
src: url(/_next/static/media/34446876aae48f71-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__objectivityMedium_Fallback_f00cf9';src: local("Arial");ascent-override: 62.79%;descent-override: 19.60%;line-gap-override: 4.94%;size-adjust: 117.37%
}.__className_f00cf9 {font-family: '__objectivityMedium_f00cf9', '__objectivityMedium_Fallback_f00cf9'
}.__variable_f00cf9 {--font-objectivity-medium: '__objectivityMedium_f00cf9', '__objectivityMedium_Fallback_f00cf9'
}

